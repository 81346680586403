import facepic from "./pics/facepic.png";

import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainBody>
        <About />
      </MainBody>
    ),
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

function MainBody(props) {
  return (
    <div class="flex h-screen">
      {!!props.leftLink ? (
        <Link class="w-4/12	flex-initial hover:bg-green flex justify-items-center" to={props.leftLink}>
          <button class="hover:green whitespace-nowrap w-full">{props.leftHoverText}</button>
        </Link>
      ) : (
        <div class="w-4/12 flex-initial" />
      )}
      <div className="pt-32 font-mono ui-monospace">{props.children}</div>
      {!!props.rightLink ? (
        <Link class="w-4/12	flex-initial hover:bg-green flex justify-items-center" to={props.rightLink}>
          <button class="hover:green whitespace-nowrap w-full">{props.rightHoverText}</button>
        </Link>
      ) : (
        <div class="w-4/12 flex-initial" />
      )}
    </div>
  );
}
function About() {
  return (
    <>
      <div class="text-5xl flex justify-between">
        <div>
          <h1 class="text-yellow">
            Henry Quan <br />
          </h1>
          <h1 class="text-yellow pb-8">权恒</h1>
        </div>
        <img src={facepic} width={200} height={100} alt="my face" />
      </div>
      <h2 class="text-3xl pb-2 text-brown">About the blog</h2>
      <p class="text-gray pb-8">
        I write about software, business, and politics. I work on mortgage
        software by day and think about quitting by night. My writings are an outlet when I'm not at my best, don't read too much into them. Reach me at henryquanquanhenry@gmail.com.
      </p>
    </>
  );
}

export default App;
